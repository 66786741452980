import { AspectRatio, Flex, Link, VStack } from '@chakra-ui/layout';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Container } from '@outdoorly/container';
import { LogoWordmarkRemarkCompany } from '@outdoorly/logo';
import { Text } from '@outdoorly/text';
import { NextSeo } from 'next-seo';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { PageLayout } from '../components/page-layout';
import { useAuth } from '../providers/auth';

import NextImage from 'next/image';

import natureImage from '../public/images/nature.png';
import natureMobile from '../public/images/nature-mobile.png';

const UnauthedLayout = ({ children }) => {
  return (
    <>
      <Box
        as="header"
        position="relative"
        borderBottomColor="scheme.border"
        borderBottomWidth={1}
      >
        <Container py={6} as={Flex} justify={['center', null, null, 'start']}>
          <NextLink href="/" passHref>
            <Box as="a" aria-label="Home">
              <LogoWordmarkRemarkCompany height={30} />
            </Box>
          </NextLink>
        </Container>
      </Box>
      {children}
    </>
  );
};

UnauthedLayout.propTypes = {
  children: PropTypes.node,
};

export const Base404 = () => {
  const [didClick, setDidClick] = useState(false);
  const breakpoint = useBreakpointValue({ base: 'mobile', md: 'desktop' });
  const videoRef = useRef(null);

  return (
    <Container as={VStack} spacing={16} maxW="7xl" pt={16} pb={16}>
      <Text as="h1" size="5xl" fontWeight="bold" align="center" maxW="3xl">
        So... you&apos;re definitely not supposed to be here.
      </Text>
      <Text as="h2" size="2xl" align="center" color="scheme.textSecondary">
        But! While you&apos;re here, why don&apos;t you watch this nice nature
        video:
      </Text>
      <Box
        w="full"
        cursor={didClick ? 'default' : 'pointer'}
        onClick={() => {
          if (!didClick) {
            setDidClick(true);
            videoRef.current?.playVideo();
          }
        }}
      >
        <AspectRatio
          w="full"
          ratio={16 / 9}
          display={didClick ? 'block' : 'none'}
          borderRadius="2xl"
          overflow="hidden"
        >
          <YouTube
            videoId="dQw4w9WgXcQ"
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                playsinline: 1,
                modestbranding: 1,
              },
            }}
            onReady={(e) => (videoRef.current = e.target)}
          />
        </AspectRatio>
        <AspectRatio
          w="full"
          ratio={16 / 9}
          display={didClick ? 'none' : 'block'}
        >
          <NextImage
            src={breakpoint === 'mobile' ? natureMobile : natureImage}
            placeholder="blur"
            layout="fill"
            objectFit="cover"
          />
        </AspectRatio>
      </Box>

      <Text color="scheme.textSecondary" as="p" size="xl" align="center">
        In case it isn&apos;t clear, this is our 404 page. If you need help with
        anything, please email us at{' '}
        <Link href="mailto:help@outdoorly.com">help@outdoorly.com</Link>.
      </Text>
    </Container>
  );
};

const Page404 = () => {
  const { isAuthed } = useAuth();

  if (isAuthed)
    return (
      <PageLayout backgroundColor="scheme.bgPrimary">
        <NextSeo
          title="404: Not Found"
          robotsProps={{
            nosnippet: true,
            notranslate: true,
            noimageindex: true,
            noarchive: true,
            maxSnippet: -1,
            maxImagePreview: 'none',
            maxVideoPreview: -1,
          }}
        />
        <Base404 />
      </PageLayout>
    );

  return (
    <UnauthedLayout>
      <NextSeo
        title="404: Not Found"
        robotsProps={{
          nosnippet: true,
          notranslate: true,
          noimageindex: true,
          noarchive: true,
          maxSnippet: -1,
          maxImagePreview: 'none',
          maxVideoPreview: -1,
        }}
      />
      <Base404 />
    </UnauthedLayout>
  );
};

export default Page404;
